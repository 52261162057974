import React, { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { withTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { AvailableInLanguage } from "../availableInLanguage";
import { SettingsContext } from "../settingsContext";
import { SearchContext } from "../searchContext";
import { NoOptionsMessage } from "../noOptionsMessage";

import { dataServices } from "../../services/dataServices";
import { searchApplication, consoleLogger, queryParamName } from "../../services/utilities";

import { IconSearch } from "../icons";
import axios from "axios";
import IconClose from "../icons/iconClose";

/**
 * Component to render search form
 * @param {*} props
 * @returns render search form
 */
function SearchForm(props) {
    const { sortOrderOptions, searchPerformed, t, i18n } = props;
    const [FormSearch, setFormSearch] = useState(false);
    const [updateOccupations, setUpdateOccupations]  = useState([]);
    const updatedManufacturerObj = {
        label: "Manufacturer",
        label_en: "Manufacturer",
        value: "Manufacturer"
    };

    const history = useHistory();

    const settings = React.useContext(SettingsContext);

    const searchContext = React.useContext(SearchContext);

    const appState = searchContext.appState;

    const setAppState = searchContext.setAppState;

    let details = navigator.userAgent;

    let regexp = /android|iphone/i;

    let isMobileDevice = regexp.test(details);

    const countrySelected = (code) => {
        var selected = appState.countries.filter((item) => item.value === code);
        return selected;
    };

    const minimumInputLength = isNaN(parseInt(settings.minimumInputLength)) ? 2 : parseInt(settings.minimumInputLength);

    const removeFocus = () => {
        setTimeout(function () {
            // Give the document focus
            window.focus();

            // Remove focus from any focused element
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }, 2);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 334) {
                setFormSearch(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (settings.standalone) {
            setFormSearch(true)
        }
    }, [settings.standalone])

    /**
     * Event listner when search button clicked
     * @param {*} e
     */
    const onSearchPerformed = (e) => {
        e.preventDefault();
        const url = new URL(e.target.action);

        // Get the query parameters
        const urlParams = new URLSearchParams(url.search);
        var stateParam = urlParams.get('state');
        if (!stateParam) stateParam = urlParams.get('supportedState');

        var occupation =
            appState.occupation != null ? appState.occupation.value ?? "" : "";

        var crop = appState.crop !== null ? appState.crop.value ?? "" : "";
        var pest = appState.pest != null ? appState.pest.value ?? "" : "";

        const supportedState = stateParam != null ? stateParam.split(',') : [];


        // Hardcoded filter object
        const filterObject = {
            activeIngredients: "",
            states: []
        };

        if ((crop === "" && pest === "") || occupation === "") {
            setAppState({
                ...appState,
                showError: true,
            });
        } else {
            dataServices
                .getProducts(appState.country, settings.getlng(), crop, pest, stateParam, appState.pageSize, 1, settings, JSON.stringify(filterObject), "asc", "product")
                .then((response) => {
                    if (response.status === 200) {
                        if (settings.isGTMEnabled) {
                            searchApplication.pushGtm(
                                appState.country,
                                appState.countryName,
                                appState.language ? appState.language.label : null,
                                appState.language ? appState.language.value : null,
                                appState.occupation !== null ? appState.occupation.label : "",
                                appState.occupation !== null
                                    ? appState.occupation.label_en
                                    : "",
                                appState.crop !== null ? appState.crop.value : "",
                                appState.crop !== null ? appState.crop.label : "",
                                appState.crop !== null ? appState.crop.label_en : "",
                                appState.pest !== null ? appState.pest.value : "",
                                appState.pest !== null ? appState.pest.label : "",
                                appState.pest !== null ? appState.pest.label_en : "",
                                response.data ? response.data.count : null
                            );
                        }
                        searchPerformed(response.data);
                        scrollToSearchResult();
                        setFormSearch(false);
                    } else {
                        consoleLogger(["SearchForm:getProducts", response]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:getProducts", error]);
                });
        }

        if (window.localStorage) {
            window.localStorage.setItem(queryParamName.occupation, occupation ?? '');
        }
        if (window.cabi_analytics && (appState.country && occupation && (crop || pest))) {
            if (!searchApplication.isCrawler()) {
                let analyticData = {
                    country: appState.country,
                    language: appState.language.value,
                    offline: settings.standalone
                }
                if (occupation) {
                    analyticData.occupation = occupation;
                }
                if (crop) {
                    analyticData.crop = crop;
                }
                if (pest) {
                    analyticData.pest = pest;
                }
                if (supportedState) {
                    analyticData.supportedState = supportedState;
                }

                window.cabi_analytics("search", analyticData);
            }
        }
    };

    const onCountryChange = (selectedOption) => {
        var newState = {
            ...appState,

            country: selectedOption.value,
            languages: [],
            crops: [],
            pests: [],
            crop: null,
            pest: null,
            occupation: null,

            result: null,
            activeIngredients: [],

            currentPage: 1,
            totalCount: null,

            activeIngredient: null,
            sortOrder: sortOrderOptions[0],

            disabledOccupation: false,
            disabledCrop: false,
            disabledPest: false,
            showError: false,
            filter: null,
        };

        setAppState(newState);

        if (selectedOption) {
            //TODO: Country default language
            let clng = settings.default_lang;

            let doclng = searchApplication.getDocLng();

            if (!settings.standalone) {

                let selectedCountry = appState.countries
                    .filter((item) => item.value === selectedOption.value)[0];

                let islngsupported = selectedCountry.lang.filter(s => s === doclng);

                if (islngsupported.length === 0) {
                    clng = settings.default_lang;
                }
                else {
                    clng = islngsupported[0];
                }

                i18n.changeLanguage(clng);
            }

            dataServices
                .getInitialData(selectedOption.value, settings.getlng(), null, null, null, settings)
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;

                        //create new state
                        var newState = searchApplication.transformInitialData(
                            appState,
                            settings.getlng(),
                            appState.occupation.value,
                            null,
                            null,
                            data,
                            t
                        );

                        newState = {
                            ...newState,
                            country: selectedOption.value,
                        };

                        searchPerformed(newState.result, newState);
                        if (settings.isGTMEnabled) {
                            //push gtm
                            searchApplication.pushGtm(
                                newState.country,
                                newState.countryName,
                                newState.language ? newState.language.label : null,
                                newState.language ? newState.language.value : null,
                                newState.occupation !== null ? newState.occupation.label : "",
                                newState.occupation !== null
                                    ? newState.occupation.label_en
                                    : "",
                                newState.crop !== null ? newState.crop.value : "",
                                newState.crop !== null ? newState.crop.label : "",
                                newState.crop !== null ? newState.crop.label_en : "",
                                newState.pest !== null ? newState.pest.value : "",
                                newState.pest !== null ? newState.pest.label : "",
                                newState.pest !== null ? newState.pest.label_en : "",
                                data.products ? data.products.length : null
                            );
                        }
                    } else {
                        consoleLogger([
                            "SearchForm:onCountryChange->getInitialData",
                            response,
                        ]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:onCountryChange->getInitialData", error]);
                });
        }
    };

    /**
    * Event listner for language drop down
    * @param {*} selectedOption
    */
    const onLanguageChange = (selectedOption) => {
        var newState = {
            ...appState,

            language: selectedOption,

            crops: [],
            pests: [],
            crop: null,
            pest: null,

            result: null,
            activeIngredients: [],

            activeIngredient: null,
            sortOrder: sortOrderOptions[0],

            disabledOccupation: false,
            disabledCrop: false,
            disabledPest: false,
            showError: false,
            filter: null,
        };

        setAppState(newState);

        if (selectedOption) {
            i18n.changeLanguage(selectedOption.value);

            dataServices
                .getInitialData(
                    appState.country,
                    selectedOption.value,
                    null,
                    null,
                    "",
                    settings,
                )
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;

                        //create new state
                        var newState = searchApplication.transformInitialData(
                            appState,
                            selectedOption.value,
                            appState.occupation ? appState.occupation.value : "",
                            null,
                            null,
                            data,
                            t
                        );

                        searchPerformed(newState.result, newState);

                        //update history
                        searchApplication.historyHelper.updateHistory(history, {
                            country: newState.country,
                            lng: newState.language.value,
                            crop: "",
                            pest: "",
                            filter: "",
                            activeIngredient: "",
                            sortOrder: "",
                        }, true);

                        if (settings.isGTMEnabled) {
                            //push gtm
                            searchApplication.pushGtm(
                                appState.country,
                                newState.countryName,
                                newState.language.label,
                                newState.language.value,
                                newState.occupation !== null ? newState.occupation.label : "",
                                newState.occupation !== null
                                    ? newState.occupation.label_en
                                    : "",
                                newState.crop !== null ? newState.crop.value : "",
                                newState.crop !== null ? newState.crop.label : "",
                                newState.crop !== null ? newState.crop.label_en : "",
                                newState.pest !== null ? newState.pest.value : "",
                                newState.pest !== null ? newState.pest.label : "",
                                newState.pest !== null ? newState.pest.label_en : "",
                                data.products ? data.products.length : null
                            );
                        }
                    } else {
                        consoleLogger([
                            "SearchForm:onLanguageChange->getInitialData",
                            response,
                        ]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:onLanguageChange->getInitialData", error]);
                });
        }
    };

    /**
     * Event listner for occupation dropdown change
     * @param {*} selectedOption
     */
    const onOccupationChange = (selectedOption) => {
        const newState = {
            ...appState,
            occupation: selectedOption,
        };
        setAppState(newState);
    };

    /**
     * Event lsitner for crop dropdown change
     * @param {*} selectedOption
     */
    const onCropChange = (selectedOption) => {
        if (selectedOption === null) {
            const newState = {
                ...appState,
                crop: selectedOption,
                crops: []
            };
            setAppState(newState);

            removeFocus();
        }
        else {
            const newState = {
                ...appState,
                crop: selectedOption,
            };
            setAppState(newState);
        }
    };

    const onCropMenuOpen = () => {

        if (appState.pest !== null) {
            const newState = {
                ...appState,
                crops: [],
                cropLoading: true
            };
            setAppState(newState);
            const urlParams = new URLSearchParams(window.location.search);
            var stateCode = urlParams.get('state');
            if (!stateCode) stateCode = urlParams.get('supportedState');
            dataServices
                .getCrop(
                    newState.country,
                    newState.language.value,
                    newState.pest.value,
                    settings,
                    "",
                    "",
                    stateCode ? stateCode : "",
                    null,
                    true
                )
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        setAppState({
                            ...newState,
                            crops: data.map(function (item) {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    label_en: item.nameEN,
                                };
                            }).sort(searchApplication.sortOnAsc('label')),
                            cropLoading: false
                        });
                    } else {
                        consoleLogger(["SearchForm:onCropChange->getCrop", response]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:onCropChange->getCrop", error]);
                });
        }
        else {
            const newState = {
                ...appState,
                crops: [],
            };
            setAppState(newState);
        }
    };

    /**
     * Event listner for pest drop down change
     * @param {*} selectedOption
     */
    const onPestChange = (selectedOption) => {
        if (selectedOption === null) {
            const newState = {
                ...appState,
                pest: null,
                pests: []
            };
            setAppState(newState);

            removeFocus();
        }
        else {
            const newState = {
                ...appState,
                pest: selectedOption,
            };
            setAppState(newState);
        }
    };

    const onPestMenuOpen = () => {
        if (appState.crop !== null) {
            const newState = {
                ...appState,
                pests: [],
                pestLoading: true
            };
            setAppState(newState);
            const urlParams = new URLSearchParams(window.location.search);
            var stateCode = urlParams.get('state');
            if (!stateCode) stateCode = urlParams.get('supportedState');
            dataServices
                .getPest(
                    newState.country,
                    newState.language.value,
                    newState.crop.value,
                    settings,
                    "",
                    "",
                    stateCode ? stateCode : "",
                    null,
                    true
                )
                .then((response) => {
                    if (response.status === 200) {

                        const data = response.data;
                        appState.pests = data.map(function (item) {
                            return {
                                value: item.id,
                                label: item.name,
                                label_en: item.nameEN,
                            };
                        });

                        setAppState({
                            ...newState,
                            pests: data.map(function (item) {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    label_en: item.nameEN,
                                };
                            }).sort(searchApplication.sortOnAsc('label')),
                            pestLoading: false
                        });
                        consoleLogger(["SearchForm:onCropChange->getPest", response]);
                    } else {
                        consoleLogger(["SearchForm:onCropChange->getPest", response]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:onCropChange->getPest", error]);
                });
        }
        else {
            const newState = {
                ...appState,
                pests: [],
            };
            setAppState(newState);
        }
    };

    const onPestMenuClose = () => {
    }

    const scrollToSearchResult = () => {
        var offset = 0;
        var customcontainers = document.getElementsByClassName("custom-container");
        if (customcontainers.length) {
            offset =
                customcontainers.length === 2
                    ? customcontainers[1].getBoundingClientRect().height
                    : customcontainers[0].getBoundingClientRect().height;
        }

        if (!offset) {
            var mobileHeader = document.getElementsByClassName("mobile-header-inner");
            if (mobileHeader.length) {
                offset = mobileHeader[0]
                    .querySelector(".container")
                    .getBoundingClientRect().height;
            }
        }

        const section = document.querySelector(".result-count");
        if (section) {
            var brect = section.getBoundingClientRect();
            var scrollOption = {
                top: brect.top - offset + document.documentElement.scrollTop - 16,
                left: 0,
                behavior: "smooth",
            };
            window.scrollTo(scrollOption);
        }
    };

    const getClassName = (isCrop) => {
        let clsName = "";
        if (isCrop) {
            clsName = appState.crop === null && appState.showError
                ? "basic-single error"
                : "basic-single";
        } else {
            clsName = appState.pest === null && appState.showError
                ? "basic-single error"
                : "basic-single";
        }

        clsName += " large-data";

        return clsName;
    }

    const noOptionsMessage = (arg) => {
        if (!arg.inputValue || arg.inputValue.length < minimumInputLength) {
            return t('TypeCharacters');
        } else {
            return t('Nooptions');
        }
    }

    let getCropRequest = null;
    const getCropData = (searchValue) =>
        new Promise((resolve, reject) => {

            if (searchValue.length < minimumInputLength) {
                resolve([]);
                return;
            }
            const urlParams = new URLSearchParams(window.location.search);
            var stateCode = urlParams.get('state');
            if (!stateCode) stateCode = urlParams.get('supportedState');
            if (getCropRequest) {
                getCropRequest.cancel();
                getCropRequest = axios.CancelToken.source();
            } else {
                getCropRequest = axios.CancelToken.source();
            }
            debugger
            dataServices
                .getCrop(
                    appState.country,
                    appState.language.value,
                    appState.pest === null ? null : appState.pest.value,
                    settings,
                    searchValue,
                    "",
                    stateCode ? stateCode : "",
                    getCropRequest ? getCropRequest.token : null,
                    true
                )
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        const crops = data.map(function (item) {
                            return {
                                value: item.id,
                                label: item.name,
                                label_en: item.nameEN,
                            };
                        });                 
                        resolve(crops);
                        // resolve(crops.sort(searchApplication.sortOnAsc('label')));
                    } else {
                        consoleLogger(["SearchForm:getCropData", response]);
                        reject("error");
                    }
                }, (rejectResponse) => {
                    reject(rejectResponse);
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:getCropOptions", error]);
                    reject(error);
                });
        });

    let getPestRequest = null;
    const getPestData = (searchValue) =>
        new Promise((resolve, reject) => {

            if (searchValue.length < minimumInputLength) {
                resolve([]);
                return;
            }

            if (getPestRequest) {
                getPestRequest.cancel();
                getPestRequest = axios.CancelToken.source();
            } else {
                getPestRequest = axios.CancelToken.source();
            }
            const urlParams = new URLSearchParams(window.location.search);
            var stateCode = urlParams.get('state');
            if (!stateCode) stateCode = urlParams.get('supportedState');
            dataServices
                .getPest(
                    appState.country,
                    appState.language.value,
                    appState.crop === null ? null : appState.crop.value,
                    settings,
                    searchValue,
                    "",
                    stateCode ? stateCode : "",
                    getPestRequest ? getPestRequest.token : null,
                    true
                )
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        const pests = data.map(function (item) {
                            return {
                                value: item.id,
                                label: item.name,
                                label_en: item.nameEN,
                            };
                        });

                        resolve(pests);
                    } else {
                        consoleLogger(["SearchForm:getPest", response]);
                        reject("error");
                    }

                }, (rejectResponse) => {
                    reject(rejectResponse);
                })
                .catch(function (error) {
                    consoleLogger(["SearchForm:getPest", error]);
                    reject(error);
                });
        });

    const handleFormSearch = () => {
        setFormSearch((prev) => !prev);

    }

    useEffect(() => {
        const options = searchApplication.getOccupation(appState.occupations)
            .map(el => ({
                ...el,
                displayName: el.displayName.split(/[\r\n:]+/).join(''),
                label: el.label.split(/[\r\n:]+/).join(''),
                label_en : el.label_en.split(/[\r\n:]+/).join('')
            }));
            setUpdateOccupations(options)
            
    }, [appState?.occupations]);

    return (
        <section className="h-background">
            <div className={isMobileDevice ? "container pe-0 ps-0" : "container py-5"}>
                <div className={isMobileDevice ? "s-f w-100 " : "row s-f"}>
                    <div className="col-sm-12 p-0">
                        {settings.is_mobile && !isMobileDevice ? <div className="offline-search"
                            dangerouslySetInnerHTML={{ __html: t('OfflineSearch') }} /> : null}
                        {isMobileDevice ?
                            <>
                                <div className="control-container w-100 position-relative d-flex ">
                                    <div
                                        className={FormSearch ? "control-container w-100 position-relative bg-secondary border-0 d-flex align-items-center fw-bold p-2" : "control-container w-100 position-relative bg-secondary d-flex align-items-center fw-bold p-2"}
                                        onClick={handleFormSearch}
                                    >
                                        <Trans>
                                            NewSearch
                                        </Trans>

                                    </div>
                                    <button type="submit" id="find-product"
                                        className="btn d-md-flex align-top border-0 w-100 bg-close" 
                                        onClick={handleFormSearch}
                                        aria-label={t(
                                            "CountryHeader" +
                                            searchApplication.removeWhiteSpace(appState.countryName)
                                        )}
                                        title={t(
                                            "CountryHeader" +
                                            searchApplication.removeWhiteSpace(appState.countryName)

                                        )}>
                                        {FormSearch ? <IconClose className="align-text-bottom" />
                                            :
                                            <IconSearch className="ms-0  align-text-bottom" />
                                        }
                                    </button>
                                </div>
                                {FormSearch ?
                                    <form
                                        id="search-widget"
                                        className="search-app d-inline-flex align-items-end w-100 mt-0 p-2 bg-secondary"
                                        onSubmit={onSearchPerformed}
                                    >
                                        <div className={settings.standalone ? "mb-3" : ""} dangerouslySetInnerHTML={{ __html: t('OfflineSearch') }}></div>
                                        {!settings.standalone ? <div
                                            className={
                                                settings.is_mobile
                                                    ? "mb-3 control-container w-100"
                                                    : "control-container w-100"
                                            }
                                        >
                                            <label className="d-block fw-bold pb-1 pt-3 text-start"><Trans>Country</Trans></label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select-react"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={searchApplication.isRTL(i18n)}
                                                isSearchable={true}
                                                name="country"
                                                options={appState.countries}
                                                placeholder={t("TypeCharacters")}
                                                value={countrySelected(appState.country)}
                                                onChange={onCountryChange}
                                                components={{ NoOptionsMessage }}
                                                width="100%"
                                            />
                                        </div> : null}
                                        {(!settings.standalone && appState.showLng) && settings.is_mobile ? <>
                                            <AvailableInLanguage languages={appState.languages} />
                                            <RenderLanguageSelector
                                                settings={settings}
                                                isRTL={searchApplication.isRTL(i18n)}
                                                language={appState.language}
                                                languages={appState.languages}
                                                placeholder={t("Language")}
                                                onLanguageChange={onLanguageChange}
                                                className={"d-block align-items-end mt-3"}
                                            />
                                        </> : null}
                                        <div className={
                                            settings.is_mobile
                                                ? "mb-3 control-container w-100"
                                                : "control-container w-100"
                                        }
                                        >
                                            <label className="d-block fw-bold pb-1 text-start"><Trans>OccupationTooltip</Trans></label>
                                            <Select
                                                className={
                                                    appState.occupation == null && appState.showError
                                                        ? "basic-single error"
                                                        : "basic-single"
                                                }
                                                classNamePrefix="select-react"
                                                isDisabled={appState.disabledCrop}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={searchApplication.isRTL(i18n)}
                                                isSearchable={true}
                                                name="occupation"
                                                options={updateOccupations}
                                                placeholder={t("OccupationTooltip")}
                                                value={appState?.occupation?.value === "Manufacturer" ? updatedManufacturerObj : appState?.occupation}
                                                onChange={onOccupationChange}
                                                components={{ NoOptionsMessage }}
                                            />
                                        </div>
                                        <div
                                            className={
                                                settings.is_mobile
                                                    ? "mb-3 control-container w-100"
                                                    : "control-container w-100"
                                            }
                                        >
                                            <label className="d-block fw-bold pb-1 text-start"><Trans>Crop</Trans></label>
                                            <AsyncSelect
                                                className={
                                                    getClassName(true)
                                                }
                                                loadingMessage={() => t('Loading')}
                                                classNamePrefix="select-react"
                                                isDisabled={appState.disabledCrop}
                                                isLoading={appState.cropLoading ?? false}
                                                isClearable={true}
                                                isRtl={searchApplication.isRTL(i18n)}
                                                isSearchable={true}
                                                name="crop"
                                                options={appState.crops.sort(
                                                    searchApplication.sortOnAsc("label")
                                                )}
                                                placeholder={t("SearchCrop")}
                                                value={appState.crop}
                                                onChange={onCropChange}
                                                loadOptions={getCropData}
                                                defaultOptions={appState.crops}
                                                noOptionsMessage={noOptionsMessage}
                                                onMenuOpen={onCropMenuOpen}
                                            />
                                        </div>
                                        <div
                                            className={
                                                settings.is_mobile
                                                    ? "mb-3 control-container w-100"
                                                    : "control-container w-100"
                                            }
                                        >
                                            <label className="d-block fw-bold pb-1 text-start"><Trans>Pest</Trans></label>
                                            <AsyncSelect
                                                className={
                                                    getClassName(false)
                                                }
                                                loadingMessage={() => t('Loading')}
                                                classNamePrefix="select-react"
                                                isDisabled={appState.disabledPest}
                                                isLoading={appState.pestLoading ?? false}
                                                isClearable={true}
                                                isRtl={searchApplication.isRTL(i18n)}
                                                isSearchable={true}
                                                name="pest"
                                                options={appState.pests.sort(
                                                    searchApplication.sortOnAsc("label")
                                                )}
                                                placeholder={t("SearchPest")}
                                                value={appState.pest}
                                                onChange={onPestChange}
                                                loadOptions={getPestData}
                                                defaultOptions={appState.pests}
                                                noOptionsMessage={noOptionsMessage}
                                                onMenuOpen={onPestMenuOpen}
                                                onMenuClose={onPestMenuClose}
                                            />
                                        </div>
                                        <button id="find-product"
                                            className="btn btn-success mt-3 d-md-flex align-top border-0 w-100"
                                            type="submit"
                                            aria-label={t(
                                                "CountryHeader" +
                                                searchApplication.removeWhiteSpace(appState.countryName)
                                            )}
                                            title={t(
                                                "CountryHeader" +
                                                searchApplication.removeWhiteSpace(appState.countryName)
                                            )}
                                        >
                                            <IconSearch className="ms-0 me-1 align-text-bottom" />
                                        </button>
                                    </form> :
                                    ''
                                }

                            </>

                            :
                            <form

                                id="search-widget"
                                className="search-app d-inline-flex align-items-end w-100"
                                onSubmit={onSearchPerformed}

                            >

                                {!settings.standalone ? <div
                                    className={
                                        settings.is_mobile
                                            ? "mb-3 control-container w-100"
                                            : "control-container w-100"
                                    }
                                >
                                    <label className="d-block fw-bold pb-1 text-start"><Trans>Country</Trans></label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select-react"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={searchApplication.isRTL(i18n)}
                                        isSearchable={true}
                                        name="country"
                                        options={appState.countries}
                                        placeholder={t("TypeCharacters")}
                                        value={countrySelected(appState.country)}
                                        onChange={onCountryChange}
                                        components={{ NoOptionsMessage }}
                                        width="100%"
                                    />

                                </div> : null}
                                {(!settings.standalone && appState.showLng) && settings.is_mobile ? <>
                                    <AvailableInLanguage languages={appState.languages} />
                                    <RenderLanguageSelector
                                        settings={settings}
                                        isRTL={searchApplication.isRTL(i18n)}
                                        language={appState.language}
                                        languages={appState.languages}
                                        placeholder={t("Language")}
                                        onLanguageChange={onLanguageChange}
                                        className={"d-block align-items-end mt-3"}
                                    />
                                </> : null}
                                <div className={
                                    settings.is_mobile
                                        ? "mb-3 control-container w-100"
                                        : "control-container w-100"
                                }
                                >
                                    <label className="d-block fw-bold pb-1 text-start"><Trans>OccupationTooltip</Trans></label>
                                    <Select
                                        className={
                                            appState.occupation == null && appState.showError
                                                ? "basic-single error"
                                                : "basic-single"
                                        }
                                        classNamePrefix="select-react"
                                        isDisabled={appState.disabledCrop}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={searchApplication.isRTL(i18n)}
                                        isSearchable={true}
                                        name="occupation"
                                        options={updateOccupations}
                                        placeholder={t("OccupationTooltip")}
                                        value={appState?.occupation?.value === "Manufacturer" ? updatedManufacturerObj : appState?.occupation}
                                        onChange={onOccupationChange}
                                        components={{ NoOptionsMessage }}
                                    />
                                </div>
                                <div
                                    className={
                                        settings.is_mobile
                                            ? "mb-3 control-container w-100"
                                            : "control-container w-100"
                                    }
                                >
                                    <label className="d-block fw-bold pb-1 text-start"><Trans>Crop</Trans></label>
                                    <AsyncSelect
                                        className={
                                            getClassName(true)
                                        }
                                        loadingMessage={() => t('Loading')}
                                        classNamePrefix="select-react"
                                        isDisabled={appState.disabledCrop}
                                        isLoading={appState.cropLoading ?? false}
                                        isClearable={true}
                                        isRtl={searchApplication.isRTL(i18n)}
                                        isSearchable={true}
                                        name="crop"
                                        options={appState.crops.sort(
                                            searchApplication.sortOnAsc("label")
                                        )}
                                        placeholder={t("SearchCrop")}
                                        value={appState.crop}
                                        onChange={onCropChange}
                                        loadOptions={getCropData}
                                        defaultOptions={appState.crops}
                                        noOptionsMessage={noOptionsMessage}
                                        onMenuOpen={onCropMenuOpen}
                                    />
                                </div>
                                <div
                                    className={
                                        settings.is_mobile
                                            ? "mb-3 control-container w-100"
                                            : "control-container w-100"
                                    }
                                >
                                    <label className="d-block fw-bold pb-1 text-start"><Trans>Pest</Trans></label>
                                    <AsyncSelect
                                        className={
                                            getClassName(false)
                                        }
                                        loadingMessage={() => t('Loading')}
                                        classNamePrefix="select-react"
                                        isDisabled={appState.disabledPest}
                                        isLoading={appState.pestLoading ?? false}
                                        isClearable={true}
                                        isRtl={searchApplication.isRTL(i18n)}
                                        isSearchable={true}
                                        name="pest"
                                        options={appState.pests.sort(
                                            searchApplication.sortOnAsc("label")
                                        )}
                                        placeholder={t("SearchPest")}
                                        value={appState.pest}
                                        onChange={onPestChange}
                                        loadOptions={getPestData}
                                        defaultOptions={appState.pests}
                                        noOptionsMessage={noOptionsMessage}
                                        onMenuOpen={onPestMenuOpen}
                                        onMenuClose={onPestMenuClose}
                                    />
                                </div>
                                <button id="find-product"

                                    className="btn btn-success mt-3 d-md-flex align-top border-0 w-100"
                                    type="submit"
                                    aria-label={t(
                                        "CountryHeader" +
                                        searchApplication.removeWhiteSpace(appState.countryName)
                                    )}
                                    title={t(
                                        "CountryHeader" +
                                        searchApplication.removeWhiteSpace(appState.countryName)
                                    )}>
                                    <IconSearch className="ms-0 me-1 align-text-bottom" />
                                </button>
                            </form>
                        }


                        {(!settings.standalone && appState.showLng) && !settings.is_mobile ? <>
                            <RenderLanguageSelector
                                settings={settings}
                                isRTL={searchApplication.isRTL(i18n)}
                                language={appState.language}
                                languages={appState.languages}
                                placeholder={t("Language")}
                                onLanguageChange={onLanguageChange}
                                className={"d-inline-flex align-items-end mt-3"}
                            />
                            <AvailableInLanguage languages={appState.languages} />
                        </> : null}
                    </div>
                </div>
            </div>
        </section>
    );
}

function RenderLanguageSelector(props) {
    const { settings, isRTL, language, languages, placeholder, onLanguageChange, className } = props;
    return (<div className={className}>
        <div className={
            settings.is_mobile
                ? "mb-3 control-container"
                : "control-container"
        }
        >
            <label className="d-block fw-bold pb-1 text-start"><Trans>Language</Trans></label>
            <Select
                className="basic-single"
                classNamePrefix="select-react"
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={isRTL}
                isSearchable={true}
                name="lng"
                options={searchApplication.englishLanguageAtEnd(
                    languages
                )}
                placeholder={placeholder}
                value={language}
                onChange={onLanguageChange}
                components={{ NoOptionsMessage }}
            />
        </div>
    </div>);
}

export default withTranslation()(SearchForm);
